import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const fadeInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: calc(100% - 80px); /* Account for padding */
  max-width: 600px;
  margin: 0 auto;
  padding: 80px 0px 25px 0px;
  animation: ${fadeInFromLeft} 0.6s ease-out forwards;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: calc(100% - 40px); /* Smaller padding on mobile */
    padding: 80px 20px 15px 20px;
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
    padding: 80px 10px 15px 10px;
  }
`;

const LogoNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0; /* Prevent name from shrinking */
`;

const Name = styled.h1`
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 0;
  white-space: nowrap;
  margin-top: 5px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 20px;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 20px;
    background: #f8f2e9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgb(209, 202, 192);
    gap: 15px;
    min-width: 120px;
  }
`;

const NavLink = styled(Link)`
  color: #000005;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  white-space: nowrap;

  &:hover {
    color: #3a75c4;
  }

  &.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3a75c4;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  color: #333;
  font-size: 24px;
  margin-left: auto;
  z-index: 2;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavContainer>
      <LogoNameContainer>
        <Name>Jarett Dewbury</Name>
      </LogoNameContainer>
      <MenuButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '×' : '≡'}
      </MenuButton>
      <NavLinks isOpen={isOpen}>
        <NavLink
          to="/"
          className={location.pathname === '/' ? 'active' : ''}
          onClick={() => setIsOpen(false)}
        >
          Home
        </NavLink>
        <NavLink
        to="/posts"
        className={location.pathname.startsWith('/posts') ? 'active' : ''}
        onClick={() => setIsOpen(false)}
      >
        Posts
      </NavLink>
      </NavLinks>
    </NavContainer>
  );
};

export default Navbar;