import React from 'react';
import { Link } from 'react-router-dom';
import { Title, AnimatedSection, PostsContainer } from "../styles";
import styled from 'styled-components';

const PostsGrid = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`;

const PostLink = styled(Link)`
  text-decoration: none;
`;

const PostTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const PostCard = styled.article`
  padding: 1px;
  border-bottom: 1px solid #e6dfd6;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:hover ${PostTitle} {
    color: #3a75c4;
  }
`;

const PostDate = styled.p`
  margin-top: -10px;
  font-size: 14px;
  color: #333;
`;

const Posts = () => {
  const posts = [
    {
      title: "Navigating Undergraduate Research",
      date: "January 3, 2025",
      link: "/posts/research-advice"
    }
  ];

  return (
    <PostsContainer>
      <AnimatedSection delay="0s">
        <Title>Posts</Title>
      </AnimatedSection>
      <PostsGrid>
        {posts.map((post, index) => (
          <AnimatedSection delay={`${(index + 1) * 0.3}s`} key={index}>
            <PostLink to={post.link}>
              <PostCard>
                <PostTitle>{post.title}</PostTitle>
                <PostDate>{post.date}</PostDate>
              </PostCard>
            </PostLink>
          </AnimatedSection>
        ))}
      </PostsGrid>
    </PostsContainer>
  );
};

export default Posts;