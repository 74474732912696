import React from 'react';
import styled from 'styled-components';

const BulletContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
`;

const BulletList = styled.ul`
  list-style: none;
  padding-left: 20px;
  margin: 0;
`;

const BulletItem = styled.li`
  position: relative;
  padding-left: 25px;
  margin: 8px 0;
  line-height: 1.5;

  &:before {
    content: ${props => props.customBullet ? `"${props.customBullet}"` : '"•"'};
    position: absolute;
    left: 0;
    color: #333;
  }
`;

const BoldSentence = styled.span`
  font-weight: bold;
`;

export const CustomBulletList = ({ 
  items, 
  customBullet = '•',
  boldFirstSentence = false 
}) => {
  const renderBulletItem = (item) => {
    if (boldFirstSentence) {
      // Split by period to separate sentences
      const sentences = item.split('.');
      const firstSentence = sentences[0];
      const restOfText = sentences.slice(1).join('.');
      
      return (
        <BulletItem key={item} customBullet={customBullet}>
          <BoldSentence>{firstSentence}.</BoldSentence>
          {restOfText}
        </BulletItem>
      );
    }
    
    return (
      <BulletItem key={item} customBullet={customBullet}>
        {item}
      </BulletItem>
    );
  };

  return (
    <BulletContainer>
      <BulletList>
        {items.map(renderBulletItem)}
      </BulletList>
    </BulletContainer>
  );
};

export default CustomBulletList;