import React from 'react';
import { LinkRef, BackLink, PostParagraph, PostsContainer, PostAuthor, SubTitle, NumberedTitle, PostTitle, AnimatedSection} from "../../styles";
import CustomBulletList from '../BulletList'

const ResearchAdvice = () => {
    return (
    <PostsContainer>
      <AnimatedSection delay="0.3s">
      <BackLink to="/posts">Back to Posts</BackLink>
        <PostTitle>Navigating Undergraduate Research</PostTitle>
      </AnimatedSection>
      <AnimatedSection delay="0.6s">
        <PostAuthor>January 3, 2025 〳 Written by Jarett Dewbury</PostAuthor>
      </AnimatedSection>
      <AnimatedSection delay="0.9s">
      <PostParagraph>
        Embarking into academic research as an undergraduate can feel daunting, especially when you are unsure of where to start or what steps to take. Over the years, I have navigated the process of pursuing opportunities and conducting research at institutions including the University of Waterloo, Harvard Medical School, and MIT. As I conclude my final recruitment term, I wanted to take the opportunity to share the insights and strategies I have gathered along the way. 
      </PostParagraph>
      <PostParagraph>
        While my research background is in engineering and computer science, the principles I have learned are applicable for students interested in other research areas. Whether you are taking your first steps toward research or aiming to secure a position at a prestigious lab, this guide will provide practical advice and actionable strategies to help you confidently pursue research opportunities.
      </PostParagraph>
      <PostParagraph>

      <NumberedTitle number="1">Why pursue Undergraduate research?</NumberedTitle>
        Undergraduate research is a valuable opportunity to develop more specialized knowledge within your field and gain hands-on experience working on relevant problems. Research immerses you in the complete scientific process—from initial hypothesis and experimental design, to data analysis and communicating your results.
        Unlike many equivalent early-career industry roles, research also provides substantial project ownership that allows you to shape the direction of your work, depending on your circumstances and the lab (more on this later). This exposure early into your career is highly valuable regardless of whether you intended to pursue academia or industry in the future.
      </PostParagraph>
      <PostParagraph>
        Joining a research lab also offers more than just general skill development. You gain access to sophisticated equipment and resources that may be otherwise inaccessible to obtain independently, opening the opportunity to work on more advanced problems in your field. You also become a member of a collaborative community in the lab and department, providing a more structured environment to work on problems and receive mentorship from experienced researchers. 
      </PostParagraph>

      <PostParagraph>
      <NumberedTitle number="2">Building a foundation</NumberedTitle>
        The first thing I tell students looking to get into research is to build a foundation of knowledge and experience on your own. Seems self explanatory, however it serves two key purposes. First, it allows you to develop a relevant skill set that makes you a stronger candidate and effectively contribute once you join a lab. By building a suitable knowledge base, you can reduce the time needed to get up to speed and focus more on driving meaningful progress in your projects. This is particularly relevant for short-term research positions, where you will want to maximize your impact over the short period of time that you have available. Second, and perhaps more importantly, building experience allows you to become familiar with working on problems in the research area before committing to a full-time position. Many students rush into research positions only to discover that the day-to-day work does not align with their expectations or interests, leaving them committed for at least the remainder of the semester. While this can not always be avoided, exposing yourself to working through problems in your field of interest can help you best identify the topics and type of work that align with your interests and goals.
      <SubTitle number="a">Personal projects</SubTitle>
        Personal projects serve as low-stake opportunities to explore your research interest while building a strong technical foundation. The goal is not to create groundbreaking projects, but rather gain hands-on experience working through common problems. For instance, if you are interested in machine learning research, you can start by reimplementing papers that you find interesting or walk through a comprehensive project tutorial. I am a firm believer that the best way to learn is through developing tangible projects, as it allows you to gain direct experience building and iterating within your area of interest. The main challenge with personal projects is that it requires a lot of self-initiative and motivation to maintain, which may not align with everyone's learning style or circumstances. 
      <SubTitle number="b">Design teams and clubs</SubTitle>
        Student design teams and technical clubs at your university are another great addition or alternative to personal projects. They provide a more structured environment where you can collaborate with other students on predefined projects. At the University of Waterloo, we have a large selection of student-run <LinkRef href="https://uwaterloo.ca/sedra-student-design-centre/directory-teams" target="_blank">design teams</LinkRef> that work on competition-based projects or collaborate with companies with the goal of providing students with the opportunity to gain technical experience. They also have the added benefit of accessing university funding to provide their projects with appropriate resources, which can be more favorable than personal projects if the required resources are expensive. In my first year of university, I was greatly interested in brain-computer interfaces (BCI) and wanted to gain experience in the field. After some initial research, I quickly discovered that the majority of BCI devices were quite costly and therefore it was impractical for me to work on a personal project. This led me to join our neurotechnology design team at Waterloo, who had already invested in several BCI devices. I was able to gain hands-on experience working with BCI’s, also helping me discover that I was less interested in the BCI space, but rather in developing algorithms and machine learning models to enhance healthcare workflows. Therefore, allowing me to gain initial exposure to the BCI space through collaborative projects and better define my interests before committing myself further to a specific job or research direction. 
      </PostParagraph>

      <PostParagraph>
      <NumberedTitle number="3">Opportunity search</NumberedTitle>
        Once you have established your general research interests and the desire to participate in a research assistant position, you must now find a suitable research lab to join. 
      <SubTitle number="a">Setting clear research goals</SubTitle>
        It is critical to outline clear objectives for your research experience, both immediate and longer-term. Ask yourself:
        <CustomBulletList 
          items={[
            "What specific research areas in the field excite you the most?",
            "Which skills are you looking to develop?",
            "How much time are you looking to commit?",
            "Why type of research environment would help you best thrive?"
          ]}
          customBullet="→"
        />
        Being intentional with your goals will help you better identify opportunities that align with your interests and ambitions. This will also make it easier when discussing with prospective supervisors and lab members on why you are interested in working with them. 
      <SubTitle number="b">Understanding different research positions</SubTitle>
        Research opportunities generally fall into these categories:
        <CustomBulletList 
          items={[
            "Volunteer positions. These often serve as entry points into research and provide a more flexible time commitment which can be valuable when first participating in research.",
            "Paid Research Assistantships. Generally these opportunities entail more structured expectations and deliverables, requiring some degree of experience or skill set. Universities typically offer these internally for their own students, or for visiting students from other institutions.",
            "Formal Research Programs. These programs are full-time summer commitments, with the intention of exposing undergraduate students to research and providing professional development seminars and networking opportunities. They are typically only applicable for local students."
          ]}
          customBullet="→"
          boldFirstSentence={true}
        />
        I always suggest prioritizing opportunities that provide some form of compensation, whether it be monetary or a course credit, over unpaid positions. Unpaid positions should typically be the last resort, only if you can sustain not being paid during that period, or if the research aligns perfectly with your future goals (eg. graduate school) and there are limited alternative options. While the prospect of joining prestigious labs may be tempting, it is essential that there is a mutual and fair exchange. I often hear from students who are excited about receiving an offer from a well-known institution or lab, only to realize that the position is unpaid. They find themselves caught up in the prestige of the institution and feel compelled to accept, even if it may not be the best choice for them. It is important to remember that these professors often have no shortage of students eager to work for free, which can make it easier for them to offer unpaid positions. You should not let the institution's name overshadow the importance of fair compensation for your time and effort. This can be in the form of a strong letter of recommendation or a small stipend for your time. While prestige can be valuable and there are circumstances where labs may not have access to sufficient funding for you to join, you should focus your effort on finding a position that aligns with your goals while offering a fair exchange.
      </PostParagraph>
      <PostParagraph>
        Early in your undergrad, I recommend focusing your search on research opportunities at your home institution through paid research assistantships or volunteer positions. Universities typically provide internal funding through scholarships and grants for student research over an academic term. Professors are also largely inclined to prioritize students from their own university first, making the barrier to entry generally lower. Conducting research at your own institution allows you to explore your interests with minimal risk. You will not need to relocate or make major commitments, and if you discover the research direction is not right for you, you can more easily pivot to other opportunities. At the University of Waterloo, there are <LinkRef href="https://uwaterloo.ca/engineering/undergraduate-students/academic-support/ura" target="_blank">Undergraduate Research Assistantships (URA)</LinkRef> that offer small stipends for students to work in a lab over an academic term. The positions require a relatively low minimum time commitment, making it accessible to explore interesting research without a major commitment.
      <SubTitle number="c">Finding a lab </SubTitle>
        When it comes to finding the right lab, prioritize research alignment over the specific institution. Many students make the mistake of selecting a university based solely on its prestige, then attempting to find a lab within that somewhat relates to their interests. Prestige is not a bad thing, however, a more effective approach is to first read academic papers in your field of interest. When you find a specific paper that is particularly interesting or relevant, look up the authors and their lab to inquire about potential positions. This strategy helps ensure your work is aligned with your passions and goals, rather than purely the reputation of the institution, as it will set you up for much greater success in the long-term as a researcher. Of course, there are circumstances where you may prefer a specific location or institution due to other circumstances such as family or career aspirations. Fortunately, top universities often host leading labs and research topics, making it highly possible to balance between the two. 
      </PostParagraph>
      <PostParagraph>
        Beyond the research work in the lab, you will also want to consider factors such as the size of the lab and mentorship approach. These considerations flow directly from your research goals. Some students prefer a larger lab with many projects, while others prefer a smaller lab and more intimate research environment with fewer projects. Similarly, mentorship styles can vary significantly—some labs offer more hands-on and structured guidance, while others offer independent work with periodic feedback. From personal experience, I discovered that larger labs were less compatible with my working style. The projects at times felt less structured and it was difficult to receive timely feedback. I found more success in smaller labs where I could play a more significant role in the project and better interact with lab members. While these factors greatly vary between labs and your preferences may only become clear through experience, they are important to consider during your lab search. 
      </PostParagraph>

      <PostParagraph>
      <NumberedTitle number="4">Securing research positions</NumberedTitle>
      <SubTitle number="a">The art of cold emailing</SubTitle>
        Once you have identified the research lab you are interested in joining, success lies in personalization and persistence. When emailing a principal investigator (PI), you want to keep the email concise but informative. Their inboxes are already flooded and they rarely have time to sift through lengthy emails. Try to follow this general format:
        <CustomBulletList 
          items={[
            "Express your interest. Demonstrate specific knowledge of their work and why you are particularly interested in joining their lab. Highlighting something specific from a recent paper or work is a great way to show them that you are genuinely interested in their work and have done your due diligence.",
            "Highlight your relevant skills and experience. The key is to present these in a way that shows how your background can provide value to the work they are performing in the lab.",
            "Emphasize your intention. Highlight what you plan to accomplish if you were to join their lab. Clearly state your availability and time commitment."
          ]}
          customBullet="→"
          boldFirstSentence={true}
        />
          If you do not receive a response, consider following up once after a 1-2 week period depending on the urgency of you needing to hear back. Many PIs receive numerous emails from prospective students looking to join their lab, and do not have the bandwidth to respond to each email. For perspective, during my first year when I received an email back at my lab at Harvard Medical School, they were one of three labs that had even responded out of roughly forty that I had contacted, and the only lab to offer me an interview. Do not take it personally if you do not hear back from them—remain persistent and continue reaching out to other labs that align with your interests and goals. Remember that all it takes is a single response to open the door to transformative research opportunities, and the time invested in sending these emails is minimal compared to their potential impact on your career. 
      </PostParagraph>
      <PostParagraph>
        Consider also reaching out to other members of the lab outside of the professor, such as PhD students or postdocs. They often check their email more frequently and have more availability than the PI. Since you will primarily be working with them when you join, connecting with them and obtaining their support for you to join can significantly increase your chances, as PIs often trust their team's recommendations.
      <SubTitle number="b">Preparing for interviews</SubTitle>
        If you hear back from the PI, interviews typically progress through informal and technical stages. The process usually begins with a brief introductory call to meet with a lab personnel to discuss your interests in more detail and evaluate mutual fit. Depending on your research area, this may be followed by a technical interview in the form of a research presentation or assessment to evaluate your expertise and background in more depth.
      </PostParagraph>
      <PostParagraph>
        When preparing for the interviews, focus on standard interview preparation. Be ready to discuss the contents of your CV in detail and articulate your motivations for joining their lab. Demonstrate your interests for the research area and show your willingness to learn. They do not expect expertise in the field of research, therefore emphasizing self-initiative and independent problem-solving capabilities goes a long way to address any learning curves. If you referenced specific papers in your initial email, be prepared to discuss it in general detail. 
      </PostParagraph>
      <PostParagraph>
        Equally important is asking thoughtful questions to ensure the lab meets your needs. Reflect on your goals and preferences for a lab setting, and use the interview to confirm these align with the lab. 
      </PostParagraph>

      <PostParagraph>
      <NumberedTitle number="5">Conclusion</NumberedTitle>
        Breaking into undergraduate research is a journey that requires strategic planning and persistence. Each step detailed in this post serves a purpose in helping you find research opportunities that genuinely align with your interests and goals. A lot of these points derive from my own experience as well as observations from other students trying to get into research. It is important to take the time to understand your interests and determine if research is suitable for you, and then outline the relevant skills and approach needed to pursue opportunities. As you embark on your own research journey, I hope these insights help you navigate with greater confidence and clarity throughout each step. 
      </PostParagraph>

      </AnimatedSection>
    </PostsContainer>
  );
}

export default ResearchAdvice;