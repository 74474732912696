import { styled, keyframes, createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom';
import '../styles/index.css';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-padding-top: 80px;
  }
  body {
    background-color: #f8f2e9;
    padding-top: 50px;
  }
`;

export const Container = styled.div`
  padding: 60px 40px 40px 40px;
  min-width: 300px;
  max-width: 600px;
  margin: 0 auto;
  font-weight: medium;
  font-size: 15px;
  color: #333;
  line-height: 1.2;
`;

export const LinkRef = styled.a`
  margin: 0px;
  color: #000005;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 0.1px #3a75c4 solid;
  transition: color 0.3 ease-in;
  text-decoration: none;
  &:hover {
    color: #3a75c4;
  }
`;

export const BackLink = styled(Link)`
  display: inline-block;
  color: #333;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 14px;
  transition: color 0.2s ease;

  &:hover {
    color: #3a75c4;
  }

  &::before {
    content: '←';
    margin-right: 8px;
  }
`;

export const Paragraph = styled.div`
  padding-top: 15px;
`;

export const PostParagraph = styled.div`
  padding-top: 15px;
  line-height: 1.5;
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
`;

export const TitleNumber = styled.span`
  font-size: 14px;
  margin-right: 8px;
  color: #3a75c4;
  user-select: none;
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #333;
  font-weight: bold;
`;

export const StyledSubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-left: 20px;
  color: #333;
  font-style: italic;
`;

export const NumberedTitle = ({ number, children }) => (
  <StyledTitle>
    {number && <TitleNumber>{number}.</TitleNumber>}
    {children}
  </StyledTitle>
);

export const SubTitle = ({ number, children }) => (
  <StyledSubTitle>
    {number && <TitleNumber>{number}.</TitleNumber>}
    {children}
  </StyledSubTitle>
);

export const PostTitle = styled.div`
  font-size: 22px;
  margin-bottom: 10px;
  padding-top: 10px;
  color: #000005;
  border-bottom: 1px solid #e6dfd6;
`;

export const PostsContainer = styled.section`
  padding-top: 40px;
`;

export const PostAuthor = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
  color: #333;
`;

export const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedSection = styled.div`
  opacity: 0;
  animation: ${fadeInDown} 0.8s ease-out forwards;
  animation-delay: ${props => props.delay || '0s'};
`;