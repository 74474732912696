import React from 'react';
import { AnimatedSection } from "../styles";
import About from './About';
import Research from './Research';
import News from './News';

function HomePage() {
  return (
    <div>
      <AnimatedSection delay="0s">
        <About />
      </AnimatedSection>
      <AnimatedSection delay="0.3s">
        <Research />
      </AnimatedSection>
      <AnimatedSection delay="0.6s">
        <News />
      </AnimatedSection>
    </div>
  );
}

export default HomePage;