import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from "../src/styles";

import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import SpectroMIND from './projects/SpectroMIND';
import Posts from './components/Posts';
import ResearchAdvice from './components/posts/ResearchAdvice';

function App() {
  return (
    <Router>
      <Navbar />
      <Container>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/SpectroMIND" element={<SpectroMIND />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/posts/research-advice" element={<ResearchAdvice />} />
          </Routes>
        </main>
      </Container>
    </Router>
  );
}

export default App;